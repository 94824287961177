import { css } from "styled-components"

const constrictHorizontal = css`
  box-sizing: border-box;
  max-width: 1280px;

  padding: 0 16px;
`

export default constrictHorizontal
