import styled from "styled-components"
import media from "./mediaQueries"

const InnerContainerStyle = styled.div`
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
  max-width: 1157px;
  box-sizing: border-box;

  ${media.mobileM`
    padding-left: 3%;
    padding-right: 3%;
  `}
  ${media.laptopM`
    padding-left: 0;
    padding-right: 0;
  `}
`
export default InnerContainerStyle
