import { createGlobalStyle } from "styled-components"
import { palette } from "./index"

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%;

    @media (max-width: 320px) {
      font-size: 50%;
    }
  }

  body {
    font-family: "Roboto", sans-serif;
    font-size: 1.4rem;
    margin: 0;
    background: ${props => (props.isOrangeBgLayout ? "linear-gradient(to right, #da803a, #ff5630)" : props.backgroundColour || palette.white)};
    line-height: 1.1;
    width: 100%;
    height: 100%;

    @media (min-width: 512px) {
      background: ${props => (props.backgroundColour ? props.backgroundColour : palette.white)};
    }
  }
`
export default GlobalStyle
