import { Font } from "../utils/constants"

const brandTheme = {
  colours: {
    primary: "#fd5c3c",
    secondary: "#fc603d",
    tertiary: "#f18825",
    primaryDark: "#de4e31",
    text: {
      primary: "#fff",
      secondary: "#000"
    }
  },
  fonts: {
    primary: `${Font.ROBOTO}, sans-serif`,
    secondary: `${Font.ROBOTO}, sans-serif`,
    leoUniverse: `'${Font.ROBOTO}', sans-serif`
  },
  components: {
    button: {
      desktop: {
        padding: "20px 30px",
        fontSize: "1.6em"
      },
      mobile: {
        padding: "20px 25px",
        fontSize: "1.3em",
        sticky: {
          bottom: "0",
          left: "0",
          right: "0"
        }
      },
      small: {
        padding: "11px 16px",
        fontSize: ".8em"
      }
    },
    title: {
      desktop: {
        fontSize: "3.5em"
      },
      mobile: {
        fontSize: "2.25em"
      }
    },
    banner: {
      desktop: {
        height: "90vh"
      },
      mobile: {
        height: 100,
        heightIfStickyCta: "calc(100vh - 60px)"
      }
    },
    navigationBar: {
      atTopHeight: {
        numeric: 80,
        pixels: "80px"
      },
      scrollingHeight: {
        numeric: 56,
        pixels: "56px"
      }
    },
    cookieConsent: {
      disclaimer: {
        fontSize: "1.1em",
        colour: "#000",
        link: {
          colour: "#fd5c3c"
        }
      },
      bgColour: "#fff",
      borderColour: "#e0e0e0",
      cookieIcon: {
        bgColour: "#f1f0ec",
        colour: "#000"
      },
      toggles: {
        track: {
          offColour: "#b6b6b6",
          onColour: "#e8e0d0"
        },
        pin: {
          offColour: "#e2e2e2",
          onColour: "#e8e0d0"
        },
        labelColour: "#000",
        descriptionColour: "#c0c0c0"
      },
      buttons: {
        fontSize: "1.2em",
        textTransform: "capitalize",
        allowAll: {
          colour: "#1DAA63",
          bgColour: "#fff",
          borderRadius: "6px",
          borderColour: "#1DAA63",
          padding: "0 32px",
          maxHeight: "48px",
          boxShadow: "none",
          width: "100%",
          hover: {
            bgColour: "#fff"
          },
          textTransform: "uppercase"
        },
        manage: {
          colour: "#fd5c3c",
          bgColour: "transparent",
          hover: {
            bgColour: "transparent"
          },
          borderRadius: "6px",
          padding: "0 14px",
          maxHeight: "48px",
          width: "100%",
          textTransform: "capitalize"
        },
        confirm: {
          colour: "#fff",
          bgColour: "#1DAA63",
          borderRadius: "6px",
          padding: "0 32px",
          maxHeight: "48px",
          boxShadow: "rgba(0, 0, 0, 0.25) 0px 5px 5px -3px",
          width: "100%",
          hover: {
            bgColour: "#199155"
          },
          textTransform: "uppercase"
        },
        accept: {
          colour: "#fff",
          bgColour: "#1DAA63",
          borderRadius: "6px",
          padding: "0 32px",
          maxHeight: "48px",
          boxShadow: "rgba(0, 0, 0, 0.25) 0px 5px 5px -3px",
          width: "100%",
          textTransform: "uppercase",
          hover: {
            bgColour: "#199155"
          }
        }
      }
    }
  }
}

export default brandTheme
