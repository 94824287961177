import { css } from "styled-components"

const size = {
  monitorM: 2200,
  monitorS: 1650,
  laptopL: 1400,
  laptopM: 1200,
  laptopS: 1025,
  tabletL: 992,
  tabletM: 769,
  tabletS: 768,
  mobileXL: 600,
  mobileL: 510,
  mobileM: 425,
  mobileS: 360
}

// Iterate through the sizes and create a media template
const media = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => `
    @media (min-width: ${size[label] / 16}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export default media
