const palette = {
  crusta: "#ff7e25",
  jaffa: "#f58249",
  outrageousOrange: "#FD5C3C",
  mojo: "#c15237",
  alabaster: "#fafafa",
  shark: "#2b2d32",
  white: "#fff",
  black: "#000",
  android: "#a4c639",
  green: "#20ab62"
}

export default palette
